import React from 'react';
import { handleScroll } from 'src/utils/handleScroll';

interface ScrollAnchorProps {
  anchorHref: string;
  children: React.ReactNode;
  className?: string;
}

export function ScrollAnchor({
  anchorHref,
  children,
  className,
}: ScrollAnchorProps): JSX.Element {
  return (
    <div onClick={() => handleScroll(anchorHref)} className={className}>
      {children}
    </div>
  );
}
