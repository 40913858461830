import { windowScrollTo } from 'seamless-scroll-polyfill';

export const handleScroll = (location?: string) => {
  if (!location)
    return (
      window &&
      windowScrollTo(window, {
        top: 0,
        behavior: 'smooth',
      })
    );

  const $element = document.querySelector(`${location}`);
  const $header = document.querySelector('header');

  if ($element) {
    const elementRect = $element?.getBoundingClientRect()?.top ?? 0;
    const bodyRect = document?.body?.getBoundingClientRect()?.top ?? 0;
    const offset = $header?.getBoundingClientRect()?.bottom ?? 0;

    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window &&
      windowScrollTo(window, {
        top: offsetPosition,
        behavior: 'smooth',
      });
  }
};
