import cn from 'classnames';
import { Button } from 'src/components/Button';
import { Link } from 'src/components/Link';
import { ScrollAnchor } from 'src/components/ScrollAnchor';
// TODO: Переписать компонент

interface LinkWithAnchorProps {
  className?: string;
  link?: string;
  buttonTitle: string;
  buttonClassName?: string;
  buttonOnClickAction?: () => void;
  buttonID?: string;
  target?: string;
}

export function LinkWithAnchor({
  className = '',
  buttonClassName = '',
  link,
  target,
  buttonTitle,
  buttonOnClickAction,
  buttonID,
}: LinkWithAnchorProps) {
  const linkCheck = link && link.indexOf('#scroll') !== -1;

  return (
    <>
      {linkCheck ? (
        <ScrollAnchor className={`${className} link`} anchorHref={link}>
          <Button
            data-qa="product_scroll_to_anchor"
            id={buttonID}
            analyticsOptions={{
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: buttonTitle,
              uniqueId: `link-with-scroll-anchor-${buttonTitle}`,
              transitionType: 'inside-link',
            }}
          >
            {buttonTitle}
          </Button>
        </ScrollAnchor>
      ) : link ? (
        <Link
          target={target}
          className={cn('link', className)}
          href={link}
          withButton
        >
          <Button
            data-qa="product_link"
            className={buttonClassName}
            id={buttonID}
            analyticsOptions={{
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: buttonTitle,
              uniqueId: `link-with-anchor-button-${buttonTitle}`,
              transitionType: 'inside-link',
            }}
          >
            {buttonTitle}
          </Button>
        </Link>
      ) : (
        <Button
          className={`${className} ${buttonClassName}`}
          onClick={buttonOnClickAction}
          data-qa="product_open_modal"
          id={buttonID}
          analyticsOptions={{
            action: 'click',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: buttonTitle,
            uniqueId: `link-with-anchor-button-modal-${buttonTitle}`,
            transitionType: 'inside-link',
          }}
        >
          {buttonTitle}
        </Button>
      )}
    </>
  );
}
