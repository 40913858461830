import cn from 'classnames';
import dynamic from 'next/dynamic';
import React, { useEffect, useRef, useState } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';

import { Link } from '../Link';
import s from './VideoPlayer.module.scss';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

function PlayButton({ classname }: { classname: string }) {
  return (
    <div className={cn(s.playButtonWrapper, classname)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path d="M14 28V12L30 20L14 28Z" fill="currentColor" />
      </svg>
    </div>
  );
}

function ArrowRightIcon() {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.958984 6.14575H13.7923"
        stroke="#06BD7B"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.834 6.14583L8.70898 1"
        stroke="#06BD7B"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.834 6.14575L8.70898 11.2916"
        stroke="#06BD7B"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

// подключить
function VideoPlayerError() {
  return (
    <div className={s.playerError}>
      <div className={s.playerErrorDescription}>
        <div className={s.playerErrorTitle}>503</div>
        <div className={s.playerErrorSubtitle}>Плеер недоступен</div>
        <div className={s.playerErrorNote}>
          Но есть другие полезные страницы
        </div>
        <ul className={s.playerErrorListLink}>
          <li className={s.playerErrorListItem}>
            <Link
              className={s.playerErrorListItemLink}
              href="/about"
              id={`videoplayer-error-about`}
              analyticsOptions={{
                action: '/about',
                clickZone: 'body',
                clickElement: 'textclick',
                clickContent: 'Узнать больше о нас',
                uniqueId: `videoplayer-error-about`,
                transitionType: 'inside-link',
              }}
            >
              Узнать больше о нас <ArrowRightIcon />
            </Link>
          </li>
          <li className={s.playerErrorListItem}>
            <Link
              href="/calculator"
              className={s.playerErrorListItemLink}
              id={`videoplayer-error-calculator`}
              analyticsOptions={{
                action: '/calculator',
                clickZone: 'body',
                clickElement: 'textclick',
                clickContent: 'Рассчитать стоимость',
                uniqueId: `videoplayer-error-calculator`,
                transitionType: 'inside-link',
              }}
            >
              Рассчитать стоимость <ArrowRightIcon />
            </Link>
          </li>
          <li className={s.playerErrorListItem}>
            <Link
              href="/solutions"
              className={s.playerErrorListItemLink}
              id={`videoplayer-error-solutions`}
              analyticsOptions={{
                action: '/solutions',
                clickZone: 'body',
                clickElement: 'textclick',
                clickContent: 'Изучить решения',
                uniqueId: `videoplayer-error-solutions`,
                transitionType: 'inside-link',
              }}
            >
              Изучить решения <ArrowRightIcon />
            </Link>
          </li>
          <li className={s.playerErrorListItem}>
            <Link
              href="/advanced"
              className={s.playerErrorListItemLink}
              id={`videoplayer-error-advanced`}
              analyticsOptions={{
                action: '/advanced',
                clickZone: 'body',
                clickElement: 'textclick',
                clickContent: 'Перейти к сервисам',
                uniqueId: `videoplayer-error-advanced`,
                transitionType: 'inside-link',
              }}
            >
              Перейти к сервисам <ArrowRightIcon />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

function CustomVideoPlayerError({
  errorClassName,
}: {
  errorClassName: string;
}) {
  return (
    <div className={cn(s.customError, errorClassName)}>
      <div className={s.customErrorTitle}>Ой, что-то сломалось =(</div>
      <div className={s.customErrorDesc}>
        Плеер недоступен, попробуйте зайти позже
      </div>
    </div>
  );
}

interface VideoPlayerProps extends React.HTMLProps<HTMLVideoElement> {
  link: string;
  className?: string;
  errorClassName?: string;
  videoClassName?: string;
  previewImage?: string | undefined;
  setError?: React.Dispatch<React.SetStateAction<boolean>>;
  customError?: boolean;
  playerCode?: string;
  controls?: boolean;
  children?: React.ReactNode;
  dataQa?: string;
  withOutError?: boolean;
  customPlayButton?: string | React.ReactNode;
}

export function VideoPlayer({
  link,
  previewImage,
  errorClassName = '',
  className = '',
  videoClassName = '',
  customError,
  playerCode,
  controls,
  children,
  dataQa = 'video-player',
  withOutError,
  customPlayButton,
  ...props
}: VideoPlayerProps) {
  const ref = useRef<HTMLVideoElement>(null);
  const [isPlayed, setIsPlayed] = useState(false);
  const [error, setError] = useState(false);
  const [poster, setPoster] = useState(
    previewImage ??
      'https://cdn.cloud.ru/backend/images/video-player/preview_default.png',
  );

  const { videoPlayAnalytics } = useAnalytics();

  useEffect(() => {
    if (isPlayed) ref.current?.play();
  }, [isPlayed]);

  useEffect(() => {
    if (ref.current?.error) setError(true);
  });

  useEffect(() => {
    const handleEvent = (event: any) => {
      if (event.origin && event.origin === 'https://rutube.ru') {
        const message = JSON.parse(event.data);
        switch (message.type) {
          case 'player:changeState':
            if (message.data.state === 'playing') {
              handleSendAnalytics();
              window.removeEventListener('message', handleEvent);
            }
            break;
        }
      }
    };

    window.addEventListener('message', handleEvent);
  }, []);

  const [isSendedAnalytics, setIsSendedAnalytics] = useState(false);

  const handleSendAnalytics = () => {
    if (!isSendedAnalytics) {
      videoPlayAnalytics(link);
      setIsSendedAnalytics(true);
    }
  };

  const regexpYouTubeId =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
  const regexpRuTubeId = /rutube\.ru\/(?:video\/|play\/embed\/)(\w+)/;
  let iframe;

  const matchYoutube = link.match(regexpYouTubeId);
  if (matchYoutube) {
    const src = `https://www.youtube.com/embed/${matchYoutube[1]}`;
    iframe = (
      <ReactPlayer
        className={videoClassName}
        width="100%"
        height="100%"
        url={src}
        onPlay={handleSendAnalytics}
        data-qa={dataQa}
        controls
        playsinline
      />
    );
  }

  const matchRutube = link.match(regexpRuTubeId);
  if (matchRutube) {
    const src = `https://rutube.ru/play/embed/${matchRutube[1]}`;
    iframe = (
      <iframe
        className={videoClassName}
        src={src}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        data-qa={dataQa}
      />
    );
  }

  if (iframe) {
    return (
      <div className={cn(s.root, className)}>
        {playerCode && !withOutError ? (
          <>
            {customError ? (
              <CustomVideoPlayerError errorClassName={errorClassName} />
            ) : (
              <VideoPlayerError />
            )}
          </>
        ) : (
          <>
            {iframe}
            {children}
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(s.root, className, {
        [s.rootWithError]: error,
      })}
    >
      {error && !withOutError ? (
        <>
          {customError ? (
            <CustomVideoPlayerError errorClassName={errorClassName} />
          ) : (
            <VideoPlayerError />
          )}
        </>
      ) : (
        <>
          <video
            width="100%"
            className={videoClassName}
            ref={ref}
            src={link}
            preload="auto"
            controls={isPlayed && controls}
            autoPlay={!controls}
            disablePictureInPicture
            controlsList="nodownload noplaybackrate"
            onContextMenu={(event: any) => event.preventDefault()}
            data-qa={dataQa}
            playsInline
            poster={poster}
            {...props}
          />
          {!isPlayed && controls && (
            <div
              className={s.videoButtonWrapper}
              onClick={() => {
                videoPlayAnalytics(link);
                setIsPlayed(true);
              }}
            >
              {customPlayButton ?? <PlayButton classname={s.videoButton} />}
            </div>
          )}
        </>
      )}
    </div>
  );
}
